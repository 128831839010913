import React, { forwardRef, useEffect, useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import cx from "classnames";
import * as s from "../DropdownDatePicker.module.scss";
import {
  customDatepickerButton,
  customDatepickerButton__selected,
  customDatepickerButtonProductWidget,
  customDatepickerButtonProductWidgetTitle,
  dhButton,
  dhButtonActive,
  notSelected,
} from "../DropdownDatePicker.module.scss";
import {
  addDays,
  format,
  formatISO,
  isSameDay,
  isValid,
  isWeekend,
  parseISO,
  setHours,
} from "date-fns";
import get from "lodash/get";
import Timepicker from "./Timepicker";
import useQueryParams from "../../../utils/useQueryParams";
import i18n from "../../../utils/i18n";
import DatePickerIcon from "../../../images/design-components/datepicker_icon.svg";
import updateQueryString from "../../../utils/updateQueryString";
import { getQueryParams } from "../../../utils";
import { Button } from "react-bootstrap";
import Down from "../../../images/design-components/Down.svg";

const ModifiedDropdownDatePicker = ({
  hasHourly = true,
  hasDaily = true,
  productWidget = false,
  meetingRoomForm = false,
  closeLocationDropDown = () => null,
  excludeDates = [],
  location,
}) => {
  if (!hasHourly && !hasDaily) return null;
  const [customEndDate, setCustomEndDate] = useState(null);
  const types = [
    { label: i18n.t("Hourly"), value: "hourly" },
    { label: i18n.t("Daily"), value: "daily" },
  ];
  // ============= UI UTILITIES =============
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  useEffect(() => {
    closeLocationDropDown();
  }, [datePickerOpen]);

  const [customType, setCustomType] = useQueryParams("type", "");

  const [type, setType] = useState(hasHourly ? types[0] : types[1]);

  useEffect(() => {
    if (hasHourly) {
      setType(types[0]);
    } else if (hasDaily) {
      setType(types[1]);
    }
  }, [hasHourly, hasDaily]);

  const [label, setLabel] = useState("");

  const [tempStart, setTempStart] = useState("");
  const [tempEnd, setTempEnd] = useState("");

  const params = getQueryParams();

  const isHourly =
    get(type, "value", "invalid selected") ===
    get(types, "[0].value", "invalid default");

  useEffect(() => {
    // update the values for the first time
    const t = params.get("type");
    if (!t || !types.find(({ value }) => value === t)) {
      setType(types[0]);
    } else if (t === "hourly" && hasHourly) {
      setType(types[0]);
    } else {
      setType(types[1]);
    }
    const s = parseISO(params.get("start"));
    if (isValid(s)) {
      setTempStart(s);
    }
    const e = parseISO(params.get("end"));
    if (isValid(e)) {
      setTempEnd(e);
    }
  }, []);

  const isDefaultValue =
    label === i18n.t("StartAndEndTime") || label === i18n.t("StartAndEndDate");

  useEffect(() => {
    // update the label

    const from = parseISO(params.get("start"));
    const to = parseISO(params.get("end"));

    let l = isHourly ? i18n.t("StartAndEndTime") : i18n.t("StartAndEndDate");
    if (isHourly) {
      if (isValid(from) && isValid(to)) {
        l = format(from, "MMM dd, hh:mm a");
        l += ` - ${format(to, "hh:mm a")}`;
      }
    } else {
      if (isValid(from)) {
        l = format(from, "yyyy-MM-dd");
        if (isValid(to) && !isSameDay(from, to)) {
          l += ` - ${format(to, "yyyy-MM-dd")}`;
        }
      }
    }
    setLabel(l);
  }, [params.get("type"), params.get("start"), params.get("end"), type]);

  useEffect(() => {
    if (!customType) return;
    if (types.find((t) => t.value === setCustomType)) {
      setType(types.find((t) => t.value === setCustomType));
    }
  }, []);

  const handleDateChange = async (date) => {
    if (isHourly) {
      setCustomEndDate(null);
      setTempStart(date);
      setTempEnd(null);
    } else {
      const dateStart = setHours(date, 9);
      const dateEnd = setHours(date, 17);
      await updateQueryString([
        {
          key: "type",
          value: "daily",
        },
        {
          key: "start",
          value: formatISO(dateStart),
        },
        {
          key: "end",
          value: formatISO(dateEnd),
        },
      ]);
      setDatePickerOpen(false);
    }
  };

  const setDefaultDate = async () => {
    // set default date
    if (tempStart && !tempEnd && isValid(tempStart)) {
      const ts = setHours(tempStart, 9);
      const te = setHours(tempStart, 17);
      setType(types[1]);
      setTempStart(ts);
      setTempEnd(te);
      await updateQueryString([
        {
          key: "type",
          value: "daily",
        },
        {
          key: "start",
          value: formatISO(ts),
        },
        {
          key: "end",
          value: formatISO(te),
        },
      ]);
    }
  };

  useEffect(() => {
    if (isValid(tempStart) && isValid(tempEnd) && datePickerOpen) {
      setDatePickerOpen(false);
      updateQueryString([
        {
          key: "type",
          value: "hourly",
        },
        { key: "start", value: formatISO(tempStart) },
        { key: "end", value: formatISO(tempEnd) },
      ]).then(() => {});
    }
  }, [tempEnd]);

  const CustomContainer = ({ className, children }) => {
    return (
      <div>
        <CalendarContainer className={`${className} d-flex flex-column`}>
          {hasHourly && hasDaily && (
            <div>
              {types.map((t) => (
                <button
                  className={type.value === t.value ? dhButtonActive : dhButton}
                  onClick={() => {
                    setType(t);
                    setTempStart(null);
                    setTempEnd(null);
                  }}
                  onKeyDown={() => {
                    setType(t);
                    setTempStart(null);
                    setTempEnd(null);
                  }}
                  key={t.value}
                >
                  <h6>{t.label}</h6>
                </button>
              ))}
            </div>
          )}
          <div className="position-relative">{children}</div>
          {isHourly && hasHourly && (
            <Timepicker
              date={tempStart}
              endDate={tempEnd}
              setStart={setTempStart}
              setEnd={setTempEnd}
              location={location}
            />
          )}
        </CalendarContainer>
      </div>
    );
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <button
        className={cx({
          [customDatepickerButton]: true,
          [customDatepickerButton__selected]:
            params.get("start") && params.get("end"),
        })}
        onClick={() => {
          onClick();
          setDatePickerOpen(true);
        }}
        ref={ref}
      >
        <span>{label}</span>
      </button>
    );
  });

  const CustomInputProductWidget = forwardRef(({ value, onClick }, ref) => {
    return (
      <div className={customDatepickerButtonProductWidget}>
        <button
          onClick={() => {
            onClick();
            setDatePickerOpen(true);
          }}
          ref={ref}
        >
          <div className={customDatepickerButtonProductWidgetTitle}>
            <h6>{i18n.t("Time")}</h6>
            <div className={`d-flex`}>
              <img alt="" src={DatePickerIcon} />
              <p className={cx({ [notSelected]: isDefaultValue })}>{label}</p>
            </div>
          </div>
        </button>
      </div>
    );
  });

  const CustomInputMeetingRoomForm = forwardRef(({ value, onClick }, ref) => {
    return (
      <div className={s.meetingRoomForm}>
        <Button
          className={`p-0 w-100 justify-content-start`}
          onClick={() => {
            onClick();
            setDatePickerOpen(true);
          }}
          ref={ref}
        >
          <div className={"d-flex"}>
            <img className={`me-3`} alt={""} src={Down} />
            <span>{label}</span>
          </div>
        </Button>
      </div>
    );
  });

  const days = ["S", "M", "T", "W", "T", "F", "S"];
  const months = {
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    fr: [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "aout",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ],
  };

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months?.[i18n.t("lang") === "" ? "en" : "fr"][n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };

  return (
    <DatePicker
      formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 1)}
      open={datePickerOpen}
      locale={locale}
      customInput={
        productWidget ? (
          <CustomInputProductWidget />
        ) : meetingRoomForm ? (
          <CustomInputMeetingRoomForm />
        ) : (
          <CustomInput />
        )
      }
      popperPlacement="bottom-start"
      popperModifiers={[
        {
          name: "flip",
          options: {
            fallbackPlacements: ["bottom-start"],
            allowedAutoPlacements: ["bottom-start"],
          },
        },
      ]}
      dateFormat="yyyy-MM-dd"
      showPopperArrow={false}
      maxDate={customEndDate}
      filterDate={(date) => !isWeekend(date)}
      placeholderText="Start & End Date"
      minDate={isHourly ? new Date() : addDays(new Date(), 1)}
      onClickOutside={() => {
        setDatePickerOpen(false);
        setDefaultDate();
      }}
      calendarContainer={CustomContainer}
      shouldCloseOnSelect={false}
      // ^^^^ common to both hourly & daily
      selected={tempStart}
      startDate={tempStart}
      endDate={isHourly ? null : tempEnd}
      onChange={(date) => handleDateChange(date)}
      calendarClassName={"customIQ"}
      selectsRange={false}
      excludeDates={excludeDates}
    />
  );
};

export default ModifiedDropdownDatePicker;
